import { axiosConfig } from '../api/axios-config.js'

// 裝置
export const device = {
  // 取得清單
  get: (id, token) => axiosConfig.get(`/api/device/${id || ''}`, token),

  // 創建
  create: (data, token) => axiosConfig.post('/api/device', data, token),

  // 批量創建
  createBatch: (data, token) => axiosConfig.post('/api/device/batch', data, token),

  // 批量更新
  updateBatch: (data, token) => axiosConfig.patch('/api/device', data, token),

  // 更新
  update: (id, data, token) => axiosConfig.patch(`/api/device/${id}`, data, token),

  // 更改狀態
  changeStatus: (id, data, token) => axiosConfig.put(`/api/device/${id}`, data, token),

  // 刪除
  delete: (id, token) => axiosConfig.delete(`/api/device/${id}`, token)
}
